import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortRetos'
})
export class SortRetosPipe implements PipeTransform {

  transform(value: any[]): any[] {
    return value.sort((n1,n2) =>
      // console.log('>.>>.>>> n1: ' + n1.image);
      // console.log('>.>>.>>> n2: ' + n2.image);
      // let r =
      // console.log('>.>>.>>> r: ' + r);
       (n2.image + n2.name > n1.image + n1.name ? -1 : 1)

    );
  }

}
