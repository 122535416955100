import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { APIService, Stats } from '../API.service';
import { AuthService } from '../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class StatsService {

  st: Stats = {
    __typename: 'Stats'
  };

  private statsObservableBS: BehaviorSubject<Stats> =
    new BehaviorSubject<Stats>(this.st);

    get statsObservable() {
      return this.statsObservableBS.asObservable();
    }

    set statsObservableDate(stats: Stats ) {
      this.statsObservableBS.next(stats);
    }

  constructor(
    public authService: AuthService,
    public apiService: APIService) {
      this.updateStats();
  }

  public updateStats() {
    console.log(';;;;;updateStats;;;;');
    this.apiService.GetStatsByUsuario(this.authService.uiid).then(x => {
      this.statsObservableDate = x;
    });
  }
}
